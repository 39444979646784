/**
 * Vue Number Format 3.2.0
 * (c) 2021-2022 Dipak Sarkar <hello@dipaksarkar.in> (https://dipaksarkar.in/)
 * @license MIT
 */
import { resolveDirective, withDirectives, openBlock, createElementBlock } from 'vue';

var options$1 = {
  prefix: '',
  suffix: '',
  separator: ',',
  decimal: '.',
  precision: 2,
  minimumFractionDigits: false,
  prefill: true,
  reverseFill: false,
  min: false,
  max: false,
  nullValue: ''
};

/**
 * Number format function
 * @param {Object} options
 */
function NumberFormat(config = options$1) {
  this.options = Object.assign(options$1, config);
  this.input = '';
  this.number = '';
  this.isClean = true;

  this.isNull = (input = this.input) => {
    if (this.isClean) {
      return !this.numberOnly(input, new RegExp('[^0-9]+', 'gi'))
    }
    return !this.numberOnly(input, new RegExp('[^0-9\\-]+', 'gi'))
  };

  this.clean = (clean = false) => {
    this.isClean = clean;
    return this
  };

  this.sign = () => {
    if (this.isClean) {
      return this.input.toString().indexOf('-') >= 0 && this.realNumber() > 0
        ? '-'
        : ''
    }
    return this.input.toString().indexOf('-') >= 0 ? '-' : ''
  };

  function between(min, n, max) {
    return Math.max(min, Math.min(n, max))
  }

  // Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
  function fixed(precision) {
    return between(0, precision, 20)
  }

  function toFixed(numbers, precision) {
    // eslint-disable-next-line no-restricted-properties
    var exp = Math.pow(10, precision);
    var float = parseFloat(numbers) / exp || 0;
    return float.toFixed(fixed(precision))
  }

  this.toNumber = (string) => Number(string);

  this.numberOnly = (string, regExp) => string?.toString().replace(regExp, '');

  this.isNegative = this.sign() === '-';

  this.numbers = () => {
    if (this.options.reverseFill) {
      this.number = toFixed(
        this.numberOnly(this.input, /\D+/g),
        this.options.precision
      ).replace('.', this.options.decimal);
    } else if (typeof this.input === 'number') {
      this.number = this.parts(
        this.input.toString().replace('-', ''),
        '.'
      ).join(this.options.decimal);
    } else {
      this.number = this.numberOnly(
        this.input,
        new RegExp(`[^0-9\\${this.options.decimal}]+`, 'gi')
      );
      this.number = this.parts(this.number).join(this.options.decimal);
    }
    return this.number
  };

  this.realNumber = () => {
    return this.numbers().toString().replace(this.options.decimal, '.')
  };

  this.parts = (number = '', decimal = this.options.decimal) => {
    var parts = number.toString().split(decimal);

    if (parts.length > 1) {
      parts[0] = this.toNumber(parts[0]) || 0;
      parts[1] = parts.slice(1, parts.length).join('');
      parts = parts.slice(0, 2);
    }

    if (this.isClean) {
      const newNumber = this.toNumber(parts.join('.')).toFixed(
        this.options.precision
      );
      const cleanNumber = this.toNumber(newNumber);
      const minimumDigits = cleanNumber.toFixed(
        this.options.minimumFractionDigits
      );

      if (
        this.options.minimumFractionDigits &&
        this.options.minimumFractionDigits >= 0 &&
        cleanNumber.toString().length < minimumDigits.length
      ) {
        parts = minimumDigits.toString().split('.');
      } else {
        parts = cleanNumber.toString().split('.');
      }
    }

    return parts.slice(0, 2)
  };

  this.addSeparator = () => {
    var parts = this.numbers().split(this.options.decimal);
    parts[0] = parts[0]
      .toString()
      .replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${this.options.separator}`);
    return parts.join(this.options.decimal)
  };

  /**
   * Format the input with default config if there is no constructor config
   * @param {Number, String} input
   * @return {String}
   */
  this.format = (input = '') => {
    this.input = input;
    if (this.isNull() && !this.options.reverseFill)
      return this.options.nullValue
    return (
      this.sign() +
      this.options.prefix +
      this.addSeparator() +
      this.options.suffix
    )
  };

  /**
   * Unformat the input with default config if there is no constructor config
   * @param {Number, String} input
   * @return {String}
   */
  this.unformat = (input = '') => {
    this.input = input;
    if (this.isNull() && !this.options.reverseFill)
      return this.options.nullValue
    return this.sign() + this.realNumber()
  };
}

const CONFIG_KEY$1 = '__input-facade__';

/**
 * Creates a fuction to clone the objcet
 */
function cloneDeep(data) {
  return JSON.parse(JSON.stringify(data))
}

/**
 * Creates a CustomEvent('input') with detail = { facade: true }
 * used as a way to identify our own input event
 */
function FacadeInputEvent() {
  return new CustomEvent('input', {
    bubbles: true,
    cancelable: true,
    detail: { facade: true },
  })
}

/**
 * Creates a CustomEvent('change') with detail = { facade: true }
 * used as a way to identify our own change event
 */
function FacadeChangeEvent() {
  return new CustomEvent('change', {
    bubbles: true,
    cancelable: true,
    detail: { facade: true },
  })
}

/**
 * ensure that the element we're attaching to is an input element
 * if not try to find an input element in this elements childrens
 *
 * @param {HTMLInputElement} el
 */
function getInputElement(el) {
  const inputElement =
    el instanceof HTMLInputElement ? el : el.querySelector('input');

  /* istanbul ignore next */
  if (!inputElement) {
    throw new Error('facade directive requires an input element')
  }

  return inputElement
}

/**
 * Updates the cursor position to the right place after the masking rule was applied
 * @param {HTMLElement} el
 * @param {Number} position
 */
function updateCursor(el, position) {
  const setSelectionRange = () => {
    el.setSelectionRange(position, position);
  };
  setSelectionRange();
  // Android Fix
  setTimeout(setSelectionRange, 1);
}

/**
 * Updates the element's value and unmasked value based on the masking config rules
 *
 * @param {HTMLInputElement} el The input element to update
 * @param {object} [options]
 * @param {Boolean} options.emit Wether to dispatch a new InputEvent or not
 * @param {Boolean} options.force Forces the update even if the old value and the new value are the same
 */
function updateValue(
  el,
  vnode,
  { emit = true, force = false, clean = false } = {}
) {
  const { config } = el[CONFIG_KEY$1];
  let { oldValue } = el[CONFIG_KEY$1];
  let currentValue = vnode && vnode.props ? vnode.props.value : el.value;

  if (force || oldValue !== currentValue) {
    const number = new NumberFormat(config).clean(clean && !config.reverseFill);
    let masked = number.format(currentValue);
    let unmasked = number.clean(!config.reverseFill).unformat(currentValue);

    // check value with in range max and min value
    if (clean) {
      if (Number(config.max) && unmasked > Number(config.max)) {
        masked = number.format(config.max);
        unmasked = number.unformat(config.max);
      } else if (Number(config.min) && unmasked < Number(config.min)) {
        masked = number.format(config.min);
        unmasked = number.unformat(config.min);
      }
    }

    el[CONFIG_KEY$1].oldValue = masked;
    el.unmaskedValue = unmasked;

    // safari makes the cursor jump to the end if el.value gets assign even if to the same value
    if (el.value !== masked) {
      el.value = masked;
    }

    // this part needs to be outside the above IF statement for vuetify in firefox
    // drawback is that we endup with two's input events in firefox
    return emit && el.dispatchEvent(FacadeInputEvent())
  }
}

/**
 * Input event handler
 *
 * @param {Event} event The event object
 */
function inputHandler(event) {
  const { target, detail } = event;

  // We dont need to run this method on the event we emit (prevent event loop)
  if (detail?.facade) {
    return false
  }

  // since we will be emitting our own custom input event
  // we can stop propagation of this native event
  event.stopPropagation();

  let positionFromEnd = target.value.length - target.selectionEnd;
  const { oldValue, config } = target[CONFIG_KEY$1];

  updateValue(target, null, { emit: false });

  // updated cursor position
  positionFromEnd = Math.max(positionFromEnd, config.suffix.length);
  positionFromEnd = target.value.length - positionFromEnd;
  positionFromEnd = Math.max(positionFromEnd, config.prefix.length);
  updateCursor(target, positionFromEnd);

  if (oldValue !== target.value) {
    target.dispatchEvent(FacadeInputEvent());
  }
}

/**
 * Blur event handler
 *
 * @param {Event} event The event object
 */
function blurHandler(event) {
  const { target, detail } = event;

  // We dont need to run this method on the event we emit (prevent event loop)
  if (detail?.facade) {
    return false
  }

  const { oldValue } = target[CONFIG_KEY$1];

  updateValue(target, null, { force: true, emit: true, clean: true });

  if (oldValue !== target.value) {
    target.dispatchEvent(FacadeChangeEvent());
  }
}

/* eslint-disable prefer-object-spread */

const CONFIG_KEY = CONFIG_KEY$1;

var vNumber = {
  beforeMount: (el, { value, modifiers }, vnode) => {
    el = getInputElement(el);
    const config = Object.assign({}, cloneDeep(options$1), value, modifiers);
    el[CONFIG_KEY] = { config };
    // set initial value
    updateValue(el, vnode, { force: config.prefill, clean: true });
  },

  mounted: (el) => {
    el = getInputElement(el);
    const option = el[CONFIG_KEY];
    const { config } = option;
    // prefer adding event listener to parent element to avoid Firefox bug which does not
    // execute `useCapture: true` event handlers before non-capturing event handlers
    const handlerOwner = el.parentElement || el;

    // use anonymous event handler to avoid inadvertently removing masking for all inputs within a container
    const oninput = (e) => {
      if (e.target !== el) {
        return
      }
      inputHandler(e);
    };

    handlerOwner.addEventListener('input', oninput, true);

    el.onblur = (e) => blurHandler(e);

    // check decimal key and insert to current element
    // updated cursor position after format the value
    el.onkeydown = (e) => {
      if (
        ([110, 190].includes(e.keyCode) || e.key === config.decimal) &&
        el.value.includes(config.decimal)
      ) {
        e.preventDefault();
      } else if ([8].includes(e.keyCode)) {
        // check current cursor position is after separator when backspace key down
        const character = el.value.slice(el.selectionEnd - 1, el.selectionEnd);
        const replace = el.value.slice(el.selectionEnd - 2, el.selectionEnd);
        if (character === config.separator) {
          e.preventDefault();

          let positionFromEnd = el.value.length - el.selectionEnd;
          // remove separator and before character
          el.value = el.value.replace(replace, '');
          // updated cursor position
          positionFromEnd = Math.max(positionFromEnd, config.suffix.length);
          positionFromEnd = el.value.length - positionFromEnd;
          positionFromEnd = Math.max(positionFromEnd, config.prefix.length);
          updateCursor(el, positionFromEnd);
          // trigger input event
          el.dispatchEvent(new Event('input'));
        } else if ([config.prefix, '-'].includes(character)) {
          e.preventDefault();
          el.value = '';
          el.dispatchEvent(new Event('input'));
        }
      }
    };

    option.cleanup = () =>
      handlerOwner.removeEventListener('input', oninput, true);
  },

  updated: (el, { value, oldValue, modifiers }, vnode) => {
    el = getInputElement(el);
    if (value !== oldValue) {
      const { config } = el[CONFIG_KEY];
      el[CONFIG_KEY].config = Object.assign({}, config, value, modifiers);
      updateValue(el, vnode, { force: true, clean: true });
    } else {
      updateValue(el, vnode);
    }
  },

  unmounted: (el) => {
    getInputElement(el)[CONFIG_KEY].cleanup();
  },
};

const options = cloneDeep(options$1);

var script = {
  name: 'Number',
  props: {
    modelValue: {
      required: true,
    },
    nullValue: {
      type: [Number, String],
      default: () => options.nullValue,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    reverseFill: {
      type: Boolean,
      default: options.reverseFill,
    },
    prefill: {
      type: Boolean,
      default: options.prefill,
    },
    precision: {
      type: Number,
      default: () => options.precision,
    },
    minimumFractionDigits: {
      type: [Number, Boolean],
      default: () => options.minimumFractionDigits,
    },
    decimal: {
      type: String,
      default: () => options.decimal,
    },
    min: {
      type: [Number, Boolean],
      default: () => options.min,
    },
    max: {
      type: [Number, Boolean],
      default: () => options.max,
    },
    separator: {
      type: String,
      default: () => options.separator,
    },
    prefix: {
      type: String,
      default: () => options.prefix,
    },
    suffix: {
      type: String,
      default: () => options.suffix,
    },
  },
  directives: {
    number: vNumber,
  },
  emits: ['update:model-value', 'input:model-value'],
  data() {
    return {
      maskedValue: this.modelValue,
      unmaskedValue: null,
    }
  },
  methods: {
    input({ target }) {
      this.maskedValue = target.value;
      this.unmaskedValue = target.unmaskedValue;
      this.$emit('input:model-value', this.emittedValue);
    },
    change() {
      this.$emit('update:model-value', this.emittedValue);
    },
  },
  computed: {
    emittedValue() {
      return this.masked ? this.maskedValue : this.unmaskedValue
    },
    config() {
      const config = {};
      Object.keys(this.$props)
        .filter((item) => item !== 'modelValue')
        .forEach((item) => {
          config[item] = this.$props[item];
        });
      return config
    },
  },
  watch: {
    modelValue(val) {
      if (this.unmaskedValue !== val) {
        this.maskedValue = val;
      }
    },
  },
};

const _hoisted_1 = ["value"];

function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_number = resolveDirective("number");

  return withDirectives((openBlock(), createElementBlock("input", {
    type: "text",
    autocomplete: "off",
    value: $data.maskedValue,
    onChange: _cache[0] || (_cache[0] = (...args) => ($options.change && $options.change(...args))),
    onInput: _cache[1] || (_cache[1] = (...args) => ($options.input && $options.input(...args))),
    class: "v-number"
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1)), [
    [_directive_number, $options.config]
  ])
}

script.render = render;
script.__file = "src/component.vue";

const VueNumberFormat = {
  install(app, config = {}) {
    if (config) {
      Object.assign(options$1, config);
    }
    app.directive('number', vNumber);
    app.component('number', script);
  },
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueNumberFormat);
}

export { NumberFormat, VueNumberFormat as default, script as number, options$1 as options, vNumber };
